import { useEffect, useState, VFC } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../../constant';
import { useRecoilState, useRecoilValue } from 'recoil';
import { accountInfoAtom, networkAtom, operationAtom } from '../../store/atoms';
import {
  checkSignOperation,
  convertCoinAmount,
  parsePrice,
  selectImageFunction,
} from '../../utils/helper';
import LOCK_IMG from '../../assets/images/ico_lock.png';
import EYE_IMG from '../../assets/images/ico_eye.png';
import useInput from '../../hooks/useInput';
import { isDuplicate, isItemsInLimit, isOperation } from '../../lib/Vadation';
// @ts-ignore
import { Signer } from 'mitumc';
import { getOperationFromType } from '../../lib/Parse';
import download from '../../lib/Url';
import hint from '../../text/hint.json';
import { ButtonHover, RedButtonHover } from '../Button/BasicHover';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  closeModal: () => void;
}

const ConfirmSignOperationModal: VFC<Props> = ({ closeModal }) => {
  const router = useHistory();
  const { CREATE_MULTI_SIG_WALLET } = routes;
  const [visiblePassword, setVisiblePassword] = useState<'password' | 'text'>('password');
  const [privateKey, onChangePrivateKey, setPrivateKey] = useInput('');
  const { SIGN_OPERATION_COMPLETE, SIGN_OPERATION_PROCESS } = routes;
  const goPage = () => {
    router.push(CREATE_MULTI_SIG_WALLET);
  };
  const onChangePasswordType = (type: 'password' | 'text') => () => {
    setVisiblePassword(type);
  };
  const [operation, setOperation] = useRecoilState(operationAtom);
  const accountInfo = useRecoilValue(accountInfoAtom);
  const [errorMessage, setErrorMessage] = useState('');
  const [network, setNetwork] = useRecoilState(networkAtom);

  const [_items, setItems] = useState<any>([]);
  const [_hash, setHash] = useState('');
  const { t } = useTranslation();

  const onSign = () => {
    if (privateKey !== accountInfo.privateKey) {
      setErrorMessage(t('info_text_53'));
      return;
    }
    let target = operation.json;
    if (target !== null) {
      console.log(target, 'tartget');

      if (!isOperation(target)) {
        alert(`서명 추가 실패 :(', '잘못된 작업 파일입니다.`);
        return;
      }

      if (!isItemsInLimit(target)) {
        alert(
          `서명 추가 실패 :(', '작업 파일의 아이템, 키, 혹은 어마운트의 수가 정책 기준을 초과합니다`
        );
        return;
      }

      if (accountInfo.address !== target.fact.sender) {
        alert(`서명 추가 실패 :(', '이 계정에서 생성된 작업이 아닙니다.`);
        return;
      }

      if (
        isDuplicate(
          accountInfo.publicKey,
          operation.json && operation.json.fact_signs.map((x: any) => x.signer)
        )
      ) {
        alert(`서명 추가 실패 :(', '이미 서명 된 작업입니다.`);
        return;
      }
      const signer = new Signer(network.networkId, accountInfo.privateKey);
      const targets = JSON.parse(JSON.stringify(target));

      try {
        const json = signer.signOperation(targets);
        const operation = getOperationFromType(json._hint);
        // 전역 state 설정
        setOperation({
          operation,
          json,
          download: download(json),
          filename: json.hash,
        });
        setTimeout(() => {
          if (checkSignOperation(json.fact_signs, accountInfo.publicKeys)) {
            router.push(SIGN_OPERATION_COMPLETE);
            return;
          }
          router.push(SIGN_OPERATION_PROCESS);
          return;
        }, 500);
      } catch (err) {
        const operation = getOperationFromType(target._hint);
        setOperation({
          operation,
          json: target,
          download: download(target),
          filename: target.hash,
        });
        alert(`서명 추가 실패 :(', '서명을 추가하는 도중 오류가 발생하였습니다.`);
      }
    }
  };

  useEffect(() => {
    if (operation.json) {
      const { download } = operation;
      const {
        json: {
          fact: { items },
        },
      } = operation;

      const {
        json: {
          fact: { hash },
        },
      } = operation;

      setItems(items);
      setHash(hash);
    }
  }, []);

  return (
    <section className="popup" style={{ display: 'block' }}>
      <div className="pop-wrap pop-transfer">
        <strong className={`tit back`}>Sign Operation</strong>
        <div className="pop-address">
          <span className="small-tit">Receiver’s Address</span>
          <div>
            <p>
              {_items.length > 0 && _items[0].receiver
                ? _items[0].receiver
                : operation.json &&
                  operation.json.fact.items.map((x: any) => {
                    if (x.keys) {
                      return x.keys.hash + '~' + hint.address + '-' + process.env.REACT_APP_VERSION;
                    }
                  })}
            </p>
          </div>
        </div>
        <div className="pop-assets">
          <span className="small-tit">Asset</span>
          <ul>
            {operation.json &&
              operation.json.fact.items[0].amounts.map((item: any, i: number) => (
                <li key={i} className="flex-between">
                  <div>
                    <img
                      src={selectImageFunction(item.currency)}
                      alt=""
                      style={{ marginRight: '16px' }}
                    />
                    <strong style={{ padding: 0 }}>{item.currency}</strong>
                  </div>

                  <em>{convertCoinAmount(item.amount)}</em>
                </li>
              ))}
          </ul>
        </div>

        <p className="notice" style={{ whiteSpace: 'pre-line' }}>
          {t(`info_text_51`)}
        </p>
        <div className="input-box">
          <span className="input-password" style={{ display: 'block' }}>
            <input
              type={visiblePassword}
              placeholder={t('info_text_52')}
              onChange={onChangePrivateKey}
            />
            {visiblePassword === 'password' ? (
              <i
                className="ico-input ico-lock"
                onClick={onChangePasswordType('text')}
                style={{ cursor: 'pointer' }}
              >
                <img src={LOCK_IMG} alt="" />
              </i>
            ) : (
              <button
                type="button"
                className="ico-input ico-eye"
                onClick={onChangePasswordType('password')}
                style={{ display: 'block' }}
              >
                <img src={EYE_IMG} alt="" />
              </button>
            )}
          </span>
        </div>
        {errorMessage ? (
          <div style={{ color: '#f03e3e', fontWeight: '600' }}>{errorMessage}</div>
        ) : null}

        <div className="flex-between">
          <RedButtonHover
            onClick={closeModal}
            type="button"
            className="red btn-type01 small "
            style={{ color: '#fff' }}
          >
            Cancel
          </RedButtonHover>
          <ButtonHover type="button" className="btn-type01 small" onClick={onSign}>
            Accept (Sign)
          </ButtonHover>
        </div>
      </div>
    </section>
  );
};

export default ConfirmSignOperationModal;
