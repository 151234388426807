import useModal from '../../hooks/useModal';
import { useEffect, useState, VFC } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import { encode } from 'base-64';
import {
  accountInfoAtom,
  networkAtom,
  networkTypeAtom,
  operationAtom,
  setResultAtom,
  tokenFeeAtom,
} from '../../store/atoms';
import { broadcast, checkMaintainInfo } from '../../service/account';
import { OPER_CREATE_ACCOUNT, OPER_TRANSFER, routes } from '../../constant';
import hint from '../../text/hint.json';
import ExportModal from './Transfer';
import LOCK_IMG from '../../assets/images/ico_lock.png';
import EYE_IMG from '../../assets/images/ico_eye.png';
import useInput from '../../hooks/useInput';
import { toast, ToastContainer } from 'react-toastify';
import { convertToOriginalAmount, parsePrice, selectImageFunction } from '../../utils/helper';
import { isAddressValid, isInLimit } from '../../lib/Vadation';
import { useTranslation } from 'react-i18next';

// @ts-ignore
import { Generator } from 'mitumc';
import download from '../../lib/Url';
import { ButtonHover, RedButtonHover } from '../Button/BasicHover';

interface Props {
  isClose: () => void;
  address: string;
  amounts: { currency: string; amount: string }[];
  feeArr: { currency: string; fee: string }[];
}

const ModalContainer = styled.div`
  width: 100%;
  max-width: 500px;
  height: 500px;
  background: #fff;
  z-index: 10;
`;

const AccountConfirmModal: VFC<Props> = ({ isClose, address, amounts, feeArr }) => {
  const [operationState, setOperation] = useRecoilState(operationAtom);
  const [operationResultState, setOperationResultState] = useRecoilState(setResultAtom);
  const history = useHistory();
  const { json, operation } = operationState;
  const { ModalPortal, openModal, closeModal } = useModal();
  const [visiblePassword, setVisiblePassword] = useState<'password' | 'text'>('password');
  const [errorMessage, setErrorMessage] = useState('');
  const [privateKey, onChangePrivateKey, setPrivateKey] = useInput('');
  const [tokenFee, setTokenFee] = useState<{ currency: string; fee: string }[]>([]);
  const networkType = useRecoilValue(networkTypeAtom);
  const accountInfo = useRecoilValue(accountInfoAtom);
  const isMulti = accountInfo.accountType === 'multi';
  const network = useRecoilValue(networkAtom);
  const { t } = useTranslation();

  const { RESPONSE, MULTI_SIG_TRANSFER_RESPONSE, SIGN_OPERATION_PROCESS } = routes;

  const onChangePasswordType = (type: 'password' | 'text') => () => {
    setVisiblePassword(type);
  };

  const notify = () => toast.error('응답을 받지 못했습니다.(네트워크 에러)');

  const onMultiSig = () => {
    if (privateKey !== accountInfo.privateKey) {
      setErrorMessage(t('info_text_53'));
      return;
    }

    history.push(SIGN_OPERATION_PROCESS);
    // } catch (err) {
    //   console.log(err);
    //   alert(`작업을 생성할 수 없습니다 :(', '입력하신 작업 내용을 확인해 주세요.`);
    // }
  };

  const isMaintain = async () => {
    const result = await checkMaintainInfo();
    const curr = new Date().valueOf();
    const isMaintainTime =
      curr <= new Date(result.end_time).valueOf() && new Date(result.start_time).valueOf()
        ? true
        : false;

    return isMaintainTime;
  };

  const onSend = async () => {
    try {
      const isKillSwitchOn = await isMaintain();
      if (isKillSwitchOn) {
        alert('점검 시간입니다');
        window.location.href = '/';
        return;
      }
      if (privateKey !== accountInfo.privateKey) {
        setErrorMessage(t('info_text_53'));
        return;
      }

      const result = await broadcast(json, 'main');
      if (result) {
        let data;
        switch (operation) {
          case OPER_CREATE_ACCOUNT:
            data = json && json.fact.items.map((x: any) => x.keys.hash + hint.address);
            break;
          case OPER_TRANSFER:
          default:
            data = undefined;
            break;
        }
        setOperationResultState({
          isBroadcast: true,
          isStateIn: false,
          res: result,
          data,
          status: 200,
        });
        history.replace(RESPONSE);
      } else {
        notify();
        return;
        setOperationResultState({
          isBroadcast: true,
          isStateIn: false,
          res: result,
          data: undefined,
          status: 400,
        });
      }
    } catch (e) {
      notify();
      return;
      try {
        setOperationResultState({
          isBroadcast: false,
          isStateIn: false,
          res: e.response.data,
          data: undefined,
          status: e.response.data.status,
        });
      } catch (err) {
        setOperationResultState({
          isBroadcast: false,
          isStateIn: false,
          res: { title: '404 network error ' },
          data: undefined,
          status: 404,
        });
      }
    }
    // finally {
    //   history.replace('/response');
    // }
  };

  useEffect(() => {
    const amountCurrencyArr = amounts.map((a, i) => {
      return a.currency;
    });

    const resultFeeArr = feeArr.filter((f) => {
      return amountCurrencyArr.includes(f.currency);
    });
    setTokenFee(resultFeeArr);
  }, []);

  return (
    <Container className="popup" style={{ display: 'block' }}>
      <div className="pop-wrap pop-transfer">
        <strong className="tit">
          Transfer {accountInfo.accountType === 'multi' ? <em>Multi</em> : null}
        </strong>
        <div className="pop-address">
          <span className="small-tit">Receiver’s Address</span>
          <div>
            <p>{address}</p>
          </div>
        </div>
        <div className="pop-assets">
          <span className="small-tit">Asset</span>
          <ul>
            {amounts.map((amount, i) => (
              <li key={i} className="flex-between">
                <div>
                  <img
                    src={selectImageFunction(amount.currency)}
                    alt=""
                    style={{ marginRight: '16px' }}
                  />
                  <strong style={{ padding: 0 }}>{amount.currency}</strong>
                </div>

                <em>{parsePrice(amount.amount)}</em>
              </li>
            ))}
          </ul>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '12px',
            marginTop: '24px',
          }}
        >
          <ul>
            {tokenFee.map((f, i) => {
              return (
                <li key={i} style={{ marginBottom: '5px', color: 'red' }}>
                  {t(`info_text_49`)}: &nbsp;{f.fee} &nbsp;
                  {f.currency}
                </li>
              );
            })}
          </ul>
        </div>
        <p className="notice" style={{ marginTop: '0px', whiteSpace: 'pre-line' }}>
          {t(`info_text_50`)}
        </p>
        <div className="input-box">
          <span className="input-password" style={{ display: 'block' }}>
            <input
              type={visiblePassword}
              placeholder={t('info_text_52')}
              value={privateKey}
              onChange={(e) => {
                setPrivateKey(e.target.value);
              }}
            />

            {visiblePassword === 'password' ? (
              <i
                className="ico-input ico-lock"
                onClick={onChangePasswordType('text')}
                style={{ cursor: 'pointer' }}
              >
                <img src={LOCK_IMG} alt="" />
              </i>
            ) : (
              <button
                type="button"
                className="ico-input ico-eye"
                onClick={onChangePasswordType('password')}
                style={{ display: 'block' }}
              >
                <img src={EYE_IMG} alt="" />
              </button>
            )}
          </span>
        </div>
        {errorMessage ? (
          <div style={{ color: '#f03e3e', marginTop: '24px', fontWeight: '600' }}>
            {errorMessage}
          </div>
        ) : null}
        <div className="flex-between">
          <RedButtonHover
            type="button"
            className="btn-type01 small"
            onClick={isClose}
            style={{ backgroundColor: '#ff5069' }}
          >
            Cancel
          </RedButtonHover>
          {isMulti ? (
            <ButtonHover type="button" className="btn-type01 small" onClick={onMultiSig}>
              Accept
            </ButtonHover>
          ) : (
            <ButtonHover type="button" className="btn-type01 small" onClick={onSend}>
              Transfer
            </ButtonHover>
          )}
        </div>
      </div>
    </Container>
  );
};

export default AccountConfirmModal;

const Container = styled.div`
  @media (max-width: 1024px) {
    .pop-wrap {
      min-width: 335px;
    }
    .pop-transfer .pop-address div {
      width: 100%;
      word-break: break-all;
    }
  }
`;
